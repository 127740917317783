import * as React from 'react'
import styled from 'styled-components';
import Dropdown from '../utils/Dropdown';

import LanguageHandler from "../components/LanguageHandler";
import useTranslation from "../components/customHooks/translations";

import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation
} from 'react-router-dom'

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;


  li {
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    overflow-y: scroll;

    li {
      color: #fff;
    }
  }
`;


const RightNav = ({ open }) => {
const translation = useTranslation();

var contacts = [
  { id: 1, name: translation.menu_services_1, href:"#services" },
  { id: 2, name: translation.menu_services_2, href:"#services" },
  { id: 3, name: translation.menu_services_3, href:"#services" },
  { id: 4, name: translation.menu_services_4, href:"#services" }
];


  return (
    <Ul open={open}>
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><Link to="#aboutUs">{translation.aboutUs_heading}</Link></li>
      {/*<li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out">
        <a href="#services"><Dropdown title={translation.services} children={contacts}/></a>
      </li>
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><a href="#">{translation.agricultura}</a></li>-->*/}
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><a href="#prices">{translation.prices}</a></li>
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><a href="#partners">{translation.partners}</a></li>
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><a href="#carier">{translation.carrier}</a></li>
	    <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><a href="#contact">{translation.contacts}</a></li>
      <li className="font-medium text-gray-600 hover:text-gray-900 md:m-1 px-5 py-3 flex items-center transition duration-150 ease-in-out"><LanguageHandler /></li>
    </Ul>
  )
}

export default RightNav
