import React from 'react';
import CareerItem from '../components/CareerItem';

import useTranslation from "../components/customHooks/translations";

function Carrier() {
  const translation = useTranslation();

  return ( 
      <div id="carier" className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">

              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h2 className="h3 mb-4">{translation.carrier}</h2>
                  <p className="text-xl text-gray-600">{translation.carrier_heading}</p>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-1">
                <CareerItem title={translation.carrier_heading_1} 
                            expectacions={translation.carrier_expectations_1}
                            offers={translation.carrier_offers_1} />
                <CareerItem title={translation.carrier_heading_2} 
                            expectacions={translation.carrier_expectations_2}
                            offers={translation.carrier_offers_2} />
              </div>
          </div>
      </div>
);
}

export default Carrier;