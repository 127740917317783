import React from 'react';

const Thumbnail = ({ children, id, selectedKey }) => {
  const show = (id === selectedKey);
	
  return (
    <div className={`object-fill display flex justify-center ${show ? 'opacity-100 transition-all duration-1000 ease-in-out' : 'opacity-0 h-0'}`}>
	  {children}
    </div>
  );
}

/*
<div class="relative w-40 h-40 rounded-full overflow-hidden">
  <img src="https://www.w3schools.com/howto/img_avatar2.png" alt="Avatar" class="object-cover w-full h-full" />
  <div class="absolute w-full py-2.5 bottom-0 inset-x-0 bg-blue-400 text-white text-xs text-center leading-4">this is a text</div>
</div>

*/
export default Thumbnail;