export default {
	
  document_title : 'Firma de transport',
  company_name   : 'Kicsi Csaba Ferma Prod SRL',
  
  // Menu
  services : 'Servicii',
  prices   : 'Preturi',
  partners : 'Partenerii',
  carrier  : 'Cariere',
  contacts : 'Contacte',
  agricultura : 'Agricultură',
  text1    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas arcu nulla, maximus in posuere quis, luctus quis orci.',
  
  menu_services_1 : 'Domeniul alimentar',
  menu_services_2 : 'Lichide nonalimentare',
  menu_services_3 : 'SNCU',
  menu_services_4 : 'Deşeu nepericuloase',
  
  hero_connect_btn : 'Conectează-ne!',
  
  
  // First page texts
  motto_text    : '',
  motto_heading : 'Firma de transport, zi de zi mergem inainte',
  
  // Despre noi
  aboutUs_heading : 'Despre noi',
  aboutUs_text    : 'Suntem o firmă infinţată în 2006, capital 100% Românesc, activăm în domeniul transporturilor de peste 15 ani vechime.\n Suntem specializaţi ăn transportul de mărfuri atât intern şi extern şi intra-comunitar, oferind în aceaşi timp o gamă largă de opţiuni de logistică cum ar fi: transport cu temperatura controlată, transportul lichidelor alimentare şi nealimentare, dispunem de autovidanje lichide nepericuloase (SNCU)',

  // Serivici
  
  service_heading : 'Servicii noastre',
  service_text    : 'Suntem disponibil pentru Tine în servicile următoare ...',
  
  service_1       : 'Transport în domeniul alimentar temperatura controlată',
  service_1_text  : 'Deținem camioane specializate in transport cu regim termic controlat.',
  
  service_2       : 'Transport lichide nonalimentare',
  service_2_text  : 'Mărfuri în stare lichide (lapte, ulei, glucoză, vin, etc.)',
  
  service_3       : 'Transport SNCU',
  service_3_text  : 'Oferim servicii de vidanjare, transport si neutralizare SNCU',
  
  service_4       : 'Transport deșeu nepericuloase vidanjare, neutralizare',
  service_4_text  : 'Vidanjări namoluri și ape uzate, neutralizare (firma noastră dispune de contract de neutralizare deșeuri nepericuloase, precum și autorizație de tranpsort de la ANPM)',
  
  // Prices
  
  price_heading   : 'Prețuri',
  price_text      : 'Prețurile de transport sunt negociabile în funcție de volumene, trasee, autospeciale, ect.',
  
  // Carrier 
  
  carrier_heading     : 'Vezi job-uri disponibile',
  carrier_expectation : 'Aşteptării',
  carrier_offers      : 'Ceea ce oferim',
  carrier_interest    : 'Mă interesez',
  
  
  //Contact
  fullName     : 'Nume şi prenume',
  mobileNumber : 'Număr telefon',
  email        : 'Adresa de email',
  message      : 'Mesaj',
  contact_heading : 'Aveți întrebări sau aveți nevoie de alte informații? Contactează-ne!',
  contact_button  : 'Trimite',
  
  // Partners 
  
  partners_heading : 'Zilnic ajutăm afacerile să meargă mai departe…',
  
  // Footer
  footer_heading   : 'Detalii de contact',
  footer_telefon_1   : 'Telefon: 0749 164 480',
  footer_telefon_link_1   : 'tel:0749 164 480',
  footer_telefon_2   : '0769 661 138',
  footer_telefon_link_2   : 'tel:0769 661 138',
  footer_email     : 'Adresa email: kicsi.csaba74@freemail.hu',
  footer_email_link: 'mailto:kicsi.csaba74@freemail.hu',
  footer_location  : 'Sediu: Micfalău, Nr. 408 COVASNA',
  footer_cui       : 'CUI: RO 18895090',
  footer_bank      : 'IBAN: RO10 BTRL 0150 1202 A383 02KK',
  footer_bank_name : 'Banca Transilvania - Sf.Gheorghe',
  footer_web       : 'Web: www.kicsi.ro',
  
  footer_heading_2   : 'Cu ce va putem ajuta?',
  
  footer_facebook_link : 'https://www.facebook.com/KICSI-FERMA-PROD-KFT-1054143854713244',
  
  footer_social_media  : 'Social media',
  
  // carriers
  carrier_heading_1      : 'Conducator auto-tir şofer cat. C',
  carrier_expectations_1 : ['Experientă în întreţinere camioanelor','Aptitudine solicitantă','Atitudine pozitivă, independență și etică de muncă persistentă'],
  carrier_offers_1       : ['O comunitate matură de profesioniști și prieteni','Program de lucru flexibil','Salariu competent','Oportunitate de construire a carierei pe termen lung'],

  carrier_heading_2      : 'Mecanică auto camioane',
  carrier_expectations_2 : ['Permisiune conducere pentru Tir','Aptitudine solicitantă','Atitudine pozitivă, independență și etică de muncă persistentă'],
  carrier_offers_2       : ['O comunitate matură de profesioniști și prieteni','Program de lucru flexibil','Salariu competent','Oportunitate de construire a carierei pe termen lung'],
  
  partners_your_cmp   : 'Compania dvs.',
};
