import * as React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation
} from 'react-router-dom'

function FeatureItem(props) {
  return (
      <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
		  <div className="relative w-64 h-64 mt-10">
<img className="shadow-lg" src={props.img} alt="user image" />
    <div className="absolute top-0 right-0 h-3 w-3 my-10 rounded-full"></div>
  </div>
          <h4 className="text-xl text-center font-bold leading-snug tracking-tight mb-5 mt-0">{props.title}</h4>
          <p className="text-gray-600 text-center">{props.content}</p>
          <Link className="text-blue-600 mt-5" to="#contacte"></Link>
      </div>
  );
}
export default FeatureItem;

//              <g fill="none" fillRule="evenodd">
//                  <rect className="fill-current text-blue-600" width="64" height="64" rx="32" />
//			  </g>