import React, { useState } from 'react';

import useTranslation from "../components/customHooks/translations";

function AboutUs() {
	const translation = useTranslation();
	 var aboutUsText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at metus tincidunt, rutrum metus at, congue elit. Sed in orci neque. Vivamus maximus arcu eu tellus ultricies, quis imperdiet felis pulvinar. Nulla tellus lectus, tempus nec varius a, tempor sed est. Nam eget pulvinar orci. Suspendisse ut tempus nunc. Pellentesque gravida in libero non elementum. Vestibulum id turpis rhoncus odio dignissim rutrum eget porta massa. Cras nec porta urna.Suspendisse potenti. Ut vitae tempus odio. Cras convallis mauris pulvinar, volutpat ipsum et, dignissim odio. Sed et orci vel est consectetur sagittis. Vivamus vitae rhoncus est. Aliquam vitae orci eleifend, euismod libero nec, vestibulum orci. Aliquam mattis at erat id aliquet. Proin posuere elit id lectus interdum, vel efficitur nisl laoreet. Integer id nunc sit amet ex faucibus condimentum. Sed purus neque, efficitur quis lobortis sed, eleifend commodo nibh. Vivamus elit tellus, porttitor venenatis facilisis vel, lacinia at odio. Integer ut nisi ac velit consectetur interdum a ac diam. Vestibulum malesuada tellus ut nunc aliquet, a suscipit risus luctus. Proin tellus tortor, convallis non ipsum in, facilisis mattis arcu.Praesent dapibus cursus ornare. Curabitur porta, sapien quis facilisis bibendum, augue dolor porttitor dui, et malesuada tellus augue a lorem. Nullam vel convallis sapien. Curabitur aliquam faucibus malesuada. Aliquam orci dui, luctus vel cursus eu, venenatis ut dolor. Vestibulum sagittis turpis luctus dapibus faucibus. Morbi at ante semper, aliquet purus sit amet, rutrum erat.";
  
  return (
    <section id="aboutUs" className="relative">
      <div className="relative w-full mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 mt-20">
            <h2 className="h3 mb-10 mt-20">{translation.aboutUs_heading}</h2>
            <p className="text-xl text-gray-600 mb-10">{translation.aboutUs_text}</p>
            <div className="aspect-w-16 aspect-h-9 ">
                <iframe className="relative w-full h-80" src="https://www.youtube.com/embed/dAbFQKX8z4Q"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
        </div>


      </div>
    </section>
  );
}

export default AboutUs;