import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

import ContactUs from '../partials/ContactUs';

import FeatureDetail from '../components/FeatureDetail';


var detailtext = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis tellus metus. Mauris hendrerit dolor ut aliquet dictum. Praesent auctor pellentesque odio sed venenatis. Donec in iaculis tellus, quis suscipit eros. Morbi fermentum urna vel ex fermentum, id sollicitudin enim ornare. Phasellus sodales eleifend ornare. Mauris luctus libero quis magna elementum, in fringilla mi eleifend. Vestibulum posuere felis ac ante ornare condimentum. Pellentesque nec bibendum orci. Ut ultricies maximus libero ut vestibulum. Mauris tellus erat, ullamcorper aliquam dolor eget, cursus semper ligula. Nam nec nunc in metus porttitor eleifend lacinia et nulla. Donec a massa eget eros vulputate condimentum id at quam. Sed lacinia, libero id faucibus rhoncus, neque augue rutrum lorem, et venenatis nisi nibh id leo. Maecenas et ligula interdum, dictum turpis a, vehicula neque.Vivamus nec risus gravida, aliquam lacus ut, pharetra quam. Suspendisse a vulputate enim, vitae egestas libero. Nam id turpis id nulla suscipit hendrerit eu ac justo. Nullam vulputate tristique lacus, quis pulvinar diam semper vitae. Etiam eu odio erat. Vestibulum at turpis sit amet augue vehicula vestibulum non in erat. Duis ornare lorem leo, eu scelerisque tellus consequat quis. Fusce sit amet pellentesque odio, non facilisis ligula. Donec nulla felis, pulvinar nec suscipit sit amet, varius sit amet odio. Fusce vel eleifend leo. Integer nec finibus erat. Proin sit amet ante diam. Pellentesque facilisis libero odio, interdum ultricies turpis finibus condimentum. Nam tincidunt laoreet nisi a molestie. Fusce quis justo tincidunt, rutrum justo et, convallis nulla."

// 		<CarouselHome />
function Feature4() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
		<FeatureDetail 
                        imgurl="https://dummyimage.com/400x400" 
                        detailtext={detailtext}
                        title='Transport deseuri ne periculoase, vidanjare, neutralizare' />
		<ContactUs />
      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Feature4;