import React from 'react';

import useTranslation from "../components/customHooks/translations";

function Prices() {
  const translation = useTranslation();
  return ( 
      <section id="prices" className="relative bg-gray-50">
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
              <div className="py-12 md:py-20">

                  {/* Section header */}
                  <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                      <h2 className="h3 mb-4">{translation.price_heading}</h2>
                      <p className="text-xl text-gray-600">{translation.price_text}</p>
                  </div>
              </div>
          </div>
      </section>
   );
}

export default Prices;