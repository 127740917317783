const IMAGES = [
  {
    imageUrl: require('../images/slider/truck_slider_1.jpg').default,
    placeHolder: "Truck"
  },
  {
    imageUrl: require('../images/slider/truck_slider_2.jpg').default,
    placeHolder: "Truck"
  },
  {
    imageUrl: require('../images/slider/truck_slider_3.jpg').default,
    placeHolder: "Truck"
  }
];
export default IMAGES;