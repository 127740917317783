import React from 'react';
import Galery from '../partials/Galery';
import FeatureItem from '../components/FeatureItem';

import useTranslation from "../components/customHooks/translations";

function FeaturesBlocks() {
  const translation = useTranslation();
  return (
    <section id="services" className="relative">

      
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h3 mb-4">{translation.service_heading}</h2>
            <p className="text-xl text-gray-600">{translation.service_text}</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-1 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            <FeatureItem title={translation.service_1}
                         content={translation.service_1_text} 
					     img={require('../images/truck7.jpg').default}/>

            <FeatureItem title={translation.service_2}
                         content={translation.service_2_text}
						 img={require('../images/truck1.jpg').default}/>
          
            {/*<FeatureItem title={translation.service_3}
                         content={translation.service_3_text}
						 img={require('../images/truck8.jpg').default}/>
  */}
            <FeatureItem title={translation.service_4}
                         content={translation.service_4_text}
						 img={require('../images/truck13.jpg').default}/>
          </div>

        </div>
      </div>
    <Galery />
    </section>
  );
}

export default FeaturesBlocks;
