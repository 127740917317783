import React from "react";
import PropTypes from "prop-types";

function ImageItem(props) {
    return (
        <div className="lg:w-1/3 sm:w-1/2 p-4">
            <div className="flex relative">
                <img alt="gallery" className=" inset-0 w-full h-full object-cover object-center" src={props.url} />
            </div>
        </div>
    );
}
export default ImageItem;