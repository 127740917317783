import React from 'react';

import useTranslation from "../components/customHooks/translations";

function ContactUs() {
  const translation = useTranslation();

  return (
    <section id="contact" className="text-gray-600 body-font relative bg-gray-50">
      <div className="container px-5 py-24 mx-auto">
        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 className="h3 mb-4">{translation.contacts}</h2>
          <p className="text-xl text-gray-600">{translation.contact_heading}</p>
        </div>
        <form action="email_test.php" method="POST">
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/3">
                <div className="relative">
                  <label for="name" className="leading-7 text-sm text-gray-600">{translation.fullName}</label>
                  <input required type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
              </div>
              <div className="p-2 w-1/3">
                <div className="relative">
                  <label for="email" className="leading-7 text-sm text-gray-600">{translation.email}</label>
                  <input required type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
              </div>
              <div className="p-2 w-1/3">
                <div className="relative">
                  <label for="email" className="leading-7 text-sm text-gray-600">{translation.mobileNumber}</label>
                  <input required type="text" id="mobile" name="telefon" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label for="message" className="leading-7 text-sm text-gray-600">{translation.message}</label>
                  <textarea required id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <input type="submit" value={translation.contact_button} className="cursor-pointer flex mx-auto text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded text-lg" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
);
}

export default ContactUs;