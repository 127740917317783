export default {
	
  document_title : 'Shipping company',
  company_name   : 'Kicsi Csaba Ferma Prod Ltd.',
  
  // Menu
  services : 'Services',
  prices   : 'Prices',
  partners : 'Partners',
  carrier  : 'Carrier',
  contacts : 'Contact',
  agricultura : 'Agriculture',
  
  menu_services_1 : 'Transport food',
  menu_services_2 : 'Transport non-food liquids',
  menu_services_3 : 'Transport SNCU',
  menu_services_4 : 'Transport safe waste',
  
  hero_connect_btn : 'Get in touch!',
  
  
  // First page texts
  motto_text    : '',
  motto_heading : 'We go forward every day',
  
  // Despre noi
  aboutUs_heading : 'About us',
  aboutUs_text    : 'We are a company founded in 2006, with 100% Romanian capital, we have been operating in the field of transport for more than 15 years. We specialize in the transportation of goods both internally, in Romania and within the community, while offering a wide range of logistics options, such as: temperature-controlled transportation, transportation of food and non-food, non-hazardous liquid self-emptying (SNCU).',

  // Serivici
  
  service_heading : 'Our services',
  service_text    : 'We are at your disposal in the following services ...',
  
  service_1       : 'Transport in temperature controlled foods',
  service_1_text  : 'We provide trucks  with a controlled temperature system',
  
  service_2       : 'Transport of non-food liquids',
  service_2_text  : 'Goods in liquid or powder form',
  
  service_3       : 'SNCU',
  service_3_text  : 'We offer emptying, shipping and neutralization services. SNCU',
  
  service_4       : 'Transport of non-hazardous waste unloadin, neutralization',
  service_4_text  : 'Removal and neutralization of sludge and wastewater. (Our company has a contract for the neutralization of non-hazardous waste and an ANPM transit permit)',
  
  // Prices
  
  price_heading   : 'Prices',
  price_text      : 'Shipping prices are negotiable, depending on quantity, routes, trucks',
  
  // Carrier 
  
  carrier_heading     : 'Choose from out available job opportunities',
  carrier_expectation : 'Expectations',
  carrier_offers      : 'What We offer',
  carrier_interest    : "I'm interested",
  
  partners_your_cmp   : 'Your business.',
  
  
  //Contact
  fullName     : 'Name',
  mobileNumber : 'Phone number',
  email        : 'Email address',
  message      : 'Message',
  contact_heading : 'Do you have a question or need information? Contact Us!',
  contact_button  : 'Send',
  
  // Partners 
  
  partners_heading : 'Every day we help businesses move forward ...',
  // Footer
  footer_heading   : 'Contacts',
  footer_telefon_1   : 'Phone: +40 749 164 480',
  footer_telefon_link_1   : 'tel:+40 749 164 480',
  footer_telefon_2   : '+40 769 661 138',
  footer_telefon_link_2   : 'tel:+40 769 661 138',
  footer_email     : 'Email: kicsi.csaba74@freemail.hu',
  footer_email_link: 'mailto:kicsi.csaba74@freemail.hu',
  footer_location  : 'Headquarters: Micfalău, Nr. 408 COVASNA',
  footer_cui       : 'CUI: RO 18895090',
  footer_bank      : 'IBAN: RO10 BTRL 0150 1202 A383 02KK',
  footer_bank_name : 'Banca Transilvania - Sf.Gheorghe',
  footer_web       : 'Web: www.kicsi.ro',
  
  footer_facebook_link : 'https://www.facebook.com/KICSI-FERMA-PROD-KFT-1054143854713244',
  
    
  footer_social_media  : 'Social media',
  
  footer_heading_2   : 'What can we help you with?',
  
  // carriers
  carrier_heading_1      : 'Truck driver with a category C license',
  carrier_expectations_1 : ['Category C driving license','Good communication skills','Positive attitude, independence'],
  carrier_offers_1       : ['Competitive wages','Professional and friendly working community','Long-term job opportunity'],

  carrier_heading_2      : 'Truck repair',
  carrier_expectations_2 : ['Experience in truck repair','Good communication skills','Positive attitude, independence'],
  carrier_offers_2       : ['Competitive wages','Professional and friendly working community','Long-term job opportunity'],
};
