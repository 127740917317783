export default {
	
  document_title : 'Szállímányozó cég',
  company_name   : 'Kicsi Csaba Ferma Prod KFT',
  
  // Menu
  services : 'Szolgáltatásaink',
  prices   : 'Árak',
  partners : 'Partnereink',
  carrier  : 'Karrier',
  contacts : 'Kapcsolat',
  agricultura : 'Mezőgazdaság',
  
  menu_services_1 : 'Élelmiszer',
  menu_services_2 : 'Nem éllelmiszer folyadékok',
  menu_services_3 : 'SNCU',
  menu_services_4 : 'Biztonságos hulladékok',
  
  hero_connect_btn : 'Lépjen kapcsolatba velünk!',
  
  // First page texts
  motto_text    : '',
  motto_heading : 'Szállítmányozó cég, nap mint nap megyünk előre',
  
  // Despre noi
  aboutUs_heading : 'Rólunk',
  aboutUs_text    : '2006 -ban alapított társaság vagyunk, 100% -ban román tőkével, több mint 15 éve tevékenykedünk a közlekedés területén. Szakterületünk az áruszállítás mind a belső, mind a romániai, mind a közösségen belüli szállításra, miközben a logisztikai lehetőségek széles skáláját kínáljuk, mint például: hőmérséklet-szabályozott szállítás, élelmiszerek és nem élelmiszerek szállítása, nem veszélyes folyadék-önürítő. (SNCU)',

  // Serivici
  
  service_heading : 'Szolgáltatásaink',
  service_text    : 'Az alábbi szolgáltatásokban állunk rendelkezésére ...',
  
  service_1       : 'Szállítás szabályozott hőmérsékletű élelmiszerekben',
  service_1_text  : 'Biztosítunk szabályozott hőmérsékleti rendszerrel ellátott kamionokat.',
  
  service_2       : 'Nem élelmiszer jellegű folyadékok szállítása',
  service_2_text  : 'Áruk folyékony vagy por állapotban.',
  
  service_3       : 'SNCU',
  service_3_text  : 'Ürítési, szállítási és semlegesítési szolgáltatásokat kínálunk. SNCU',
  
  service_4       : 'Nem veszélyes hulladék szállítása ürítés, semlegesítés',
  service_4_text  : 'Az iszap és a szennyvíz elszállítása, semlegesítése. (Cégünk rendelkezik szerződéssel a nem veszélyes hulladékok semlegesítésére, valamint az ANPM tranzit-engedélyével)',
  
  // Prices
  
  price_heading   : 'Áraink',
  price_text      : 'A szállítási árak alkuképesek, mennyiségtől, útvonalaktól, kamionoktól függően',
  
  // Carrier 
  
  carrier_heading     : 'Válasszon az elérhető munkalehetőségeink közűl',
  carrier_expectation : 'Elvárások',
  carrier_offers      : 'Amit ajánlunk',
  carrier_interest    : 'Érdekel',
  
  partners_your_cmp   : 'Az Ön vállalkozása',
  
  //Contact
  fullName     : 'Név',
  mobileNumber : 'Telefonszám',
  email        : 'Email cím',
  message      : 'Üzenet',
  contact_heading : 'Kérdése van, vagy információra van szüksége? Írjon nekünk!',
  contact_button  : 'Küldés',
  
  // Partners 
  
  partners_heading : 'Nap mint nap segítünk a vállalkozásoknak a továbblépésben…',
  // Footer
  footer_heading   : 'Elérhetőségek',
  footer_telefon_1   : 'Telefon: +40 749 164 480',
  footer_telefon_link_1   : 'tel:+40 749 164 480',
  footer_telefon_2   : '+40 769 661 138',
  footer_telefon_link_2   : 'tel:+40 769 661 138',
  footer_email     : 'Email: kicsi.csaba74@freemail.hu',
  footer_email_link: 'mailto:kicsi.csaba74@freemail.hu',
  footer_location  : 'Székhely: Micfalău, Nr. 408 COVASNA',
  footer_cui       : 'CUI: RO 18895090',
  footer_bank      : 'IBAN: RO10 BTRL 0150 1202 A383 02KK',
  footer_bank_name : 'Banca Transilvania - Sf.Gheorghe',
  footer_web       : 'Web: www.kicsi.ro',
  
  footer_facebook_link : 'https://www.facebook.com/KICSI-FERMA-PROD-KFT-1054143854713244',
  
    
  footer_social_media  : 'Szociális média',
  
  footer_heading_2   : 'Miben tudunk segíteni?',
  
  // carriers
  carrier_heading_1      : 'Tehergépkocsi vezető C kategóriás jogosítvánnyal',
  carrier_expectations_1 : ['C kategóriás vezetői engedély','Jó kommunikációs készség','Pozitív hozzáállás, önállóság.'],
  carrier_offers_1       : ['Versenyképes bérezés','Profi és barátságos munkaközösség','Hosszútávú munkalehetőség'],

  carrier_heading_2      : 'Autó szerelő',
  carrier_expectations_2 : ['Tehergépjárművek javításában szerzett tapasztalat','Jó kommunikációs készség','Pozitív hozzáállás, önállóság.'],
  carrier_offers_2       : ['Versenyképes bérezés','Profi és barátságos munkaközösség','Hosszútávú munkalehetőség'],

};
