import * as React from 'react'


function PartnerItem(props) {


  return (
      <a className="text-center pt-5 relative flex flex-col items-center p-6 bg-white border border-gray-200 m-1 hover:shadow-lg "href = {props.link} >
      <div className="">
          <h4 className="text-xl text-center font-bold leading-snug tracking-tight mb-1 p-2">{props.title}</h4>
      </div>
      </a>
  );
}
export default PartnerItem;