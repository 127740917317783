import React from 'react';
import { Link } from 'react-router-dom';

import LanguageHandler from "../components/LanguageHandler";
import useTranslation from "../components/customHooks/translations";

class CheckItem extends React.Component {
    render() {
        return (
            <input type="checkbox" class="form-checkbox mr-3 text-green-600 mb-1" checked />
        );
    }
}

function Footer() {
	const translation = useTranslation();
	const today = new Date();
  return <footer>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">

      {/* Bottom area */}
      <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
        {/* Copyrights note */}
        <div className="content-center w-full grid grid-cols-1 md:grid-cols-4 text-center md:text-left text-sm text-gray-600 mr-4">
          <div className="w-full text-center md:p-10 p-0 pb-10">
            <p><a href="http://www.kicsi.ro"><b>{translation.company_name}</b></a></p>
          </div>
          <div className="min-w-500 text-center md:pt-10 p-0 pb-10">
            <p className="font-bold mb-5 text-md">{translation.footer_heading}</p>
            <p className="pb-1">{translation.footer_location}</p>
            <p className="pb-1">{translation.footer_cui}</p>
            <a href={translation.footer_telefon_link_1}><p className="pb-1">{translation.footer_telefon_1}</p></a>
            <a href={translation.footer_telefon_link_2}><p className="pb-1">{translation.footer_telefon_2}</p></a>
            <a href={translation.footer_email_link}><p className="pb-1">{translation.footer_email}</p></a>
            <p className="pb-1">{translation.footer_web}</p>
			
          </div>
          <div className="min-w-500 text-center md:pt-10 p-0 mr-2 ml-2">
            <p className="font-bold mb-5 text-md">{translation.footer_heading_2}</p>
            <div className="w-full border border-2 mb-1 cursor-pointer hover:shadow p-2"><a href="#services" className="w-full h-10"> <CheckItem /> {translation.service_1}</a></div>
            <div className="w-full border border-2 mb-1 cursor-pointer hover:shadow p-2"><a href="#services" className="w-full h-10"> <CheckItem /> {translation.service_2}</a></div>
            {/*<div className="w-full border border-2 mb-1 cursor-pointer hover:shadow p-2"><a href="#services" className="w-full h-10"> <CheckItem /> {translation.service_3}</a></div>*/}
            <div className="w-full border border-2 mb-1 cursor-pointer hover:shadow p-2"><a href="#services" className="w-full h-10"> <CheckItem /> {translation.service_4}</a></div>
          </div>
		  <div className="min-w-500 text-center md:pt-10 p-0 mr-2 ml-2">
			<p className="font-bold mb-5 text-md">{translation.footer_social_media}</p>
            {/* Social links */}
            <ul className="mb-4 w-full md:order-1">
              {/* Facebook */}
              <li className=" w-full justify-center items-center">
                <a href={translation.footer_facebook_link} target="blank" className=" w-full flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
	  <div className="w-100 h-10 border-t border-grey mt-10">
		<p className="text-gray-600 text-center p-2">© {today.getFullYear()} - www.kicsi.ro</p>
	  </div>
    </div>
  </footer>;
}

export default Footer;