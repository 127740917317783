import React, { useState } from 'react';

import useTranslation from "../components/customHooks/translations";

import Carousel from '../components/carousel_engine/Carousel';
import { IMAGES } from '../mock';

function HeroHome() {
  const translation = useTranslation();
  return (
    <section className="relative">
      <div className="max-w-1xl h-screen mx-auto">

        {/* Hero content */}
        <div className="m-50 pb-12 md:pt-20">
          {/* Section header */}
          <div className="max-w-6xl max-h-screen">

			<Carousel time={3000}>
				{ 
					IMAGES.map((image, index) => 
					
						<div className="relative w-screen mb-100 overflow-hidden">
						<img className="h-screen object-cover" key={index} src={image.imageUrl} alt={image.placeHolder} />
						<div className="absolute w-full  md:h-1/4  bottom-10 md:bottom-40 p-10 inset-x-0 mb-4 filter drop-shadow-md md:drop-shadow-xl">
						<div className="text-white text-3xl text-center md:text-left  md:text-4xl font-extrabold leading-tighter tracking-tighter md:mx-60">{translation.motto_heading}</div>
						<a href="#contact"><div className="text-white text-xl text-center md:-left  md:text-1xl leading-tighter tracking-tighter  md:mx-60 border-t-2 w-full md:absolute md:w-1/6 mt-4"></div></a>
					    <a href="#contact"><div className="bg-blue-900 text-white text-xl text-center md:text-left  md:text-1xl leading-tighter tracking-tighter my-10 md:mx-60 border md:absolute p-3">{translation.hero_connect_btn}</div></a>
						</div>
						</div>
					)
				}
			</Carousel>
			
		
          </div>
        </div>
      </div>
    </section>
  );
}

/*<img className="rounded shadow-xl flex md:h-1/1" src={require('../images/truck1.jpg').default} data-aos="zoom-y-out" />*/
			
            
//<div className="max-w-3xl mx-auto">
              //  <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">{translation.motto_text}</p>
              //</div>
export default HeroHome;