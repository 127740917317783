import React from 'react';

import PartnerItem from '../components/PartnerItem';

import useTranslation from "../components/customHooks/translations";

function Parteneri() {
  const translation = useTranslation();
  return (
    <section id="partners" className="relative bg-gray-50"> 
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">

              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h2 className="h3 mb-4">{translation.partners}</h2>
                  <p className="text-xl text-gray-600">{translation.partners_heading}</p>
              </div>
              <div className="grid md:grid-cols-4 grid-cols-1">
              <PartnerItem title={'SC Covalact SA'}           link={'https://covalact.ro/'}/>
              <PartnerItem title={'SC Lactate Harghita SA'}   link={'https://frisslactate.ro/'}/>
              <PartnerItem title={'SC Lactalis Romania SA'}   link={'https://www.albalact.ro/'}/>
              <PartnerItem title={'SC Ardeat Trade SRL'}      link={'https://www.ardeat.com/'}/>
              <PartnerItem title={'SC Rarula SA'}             link={''}/>
              <PartnerItem title={'SC Cow Genetics SRL'}      link={'http://hgenetics.ro/'}/>
              <PartnerItem title={'SC Lactalis Logistic SRL'} link={'https://www.albalact.ro/'}/>
              <PartnerItem title={'Food & Dairy KFT'}         link={''}/>
              <PartnerItem title={'Food & Dairy SRO'}         link={'http://www.fooddairy.sk/'}/>
              <PartnerItem title={'Hoogwegt Milk B.V'}        link={'https://hoogwegt.com/'}/>
              <PartnerItem title={'Hoogwegt Holland LTD'}     link={'https://hoogwegt.com/'}/>
              <PartnerItem title={'MC Trans SRL'}             link={'https://www.cisterna.ro/'}/>
              <PartnerItem title={'TLA - Transport Logistik Allgäu Internationale Speditions GmbH'} link={'https://www.bayern-international.de/'}/>
              <PartnerItem title={'Milk Trade KFT'}           link={''}/>
              <PartnerItem title={'Hochland Romania SRL'}     link={'https://hochland.ro/'}/>
			  <PartnerItem title={translation.partners_your_cmp}/>
              </div>
          </div>
      </div>
    </section>
);
}

export default Parteneri;