import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Feature1 from './pages/Feature1';
import Feature2 from './pages/Feature2';
import Feature3 from './pages/Feature3';
import Feature4 from './pages/Feature4';

import useTranslation from "./components/customHooks/translations";

export default function App() {
  const translation = useTranslation();
  const location = useLocation();


  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/feature1" component={Feature1}> 
        </Route>
        <Route path="/feature2">
          <Feature2 />
        </Route>
        <Route exact path="/feature3">
          <Feature3 />
        </Route>
        <Route exact path="/feature4">
          <Feature4 />
        </Route>
      </Switch>
    </>
  );
}