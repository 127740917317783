import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import AboutUs from '../partials/AboutUs';
//import CarouselHome from '../partials/CarouselHome';
//import FeaturesHome from '../partials/Features';
import Footer from '../partials/Footer';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import ContactUs from '../partials/ContactUs';
import Carrier from '../partials/Carrier';
import Prices from '../partials/Prices';
import Parteneri from '../partials/Parteneri';

import useTranslation from "../components/customHooks/translations";

// 		<CarouselHome />
function Home() {
	
  const translation = useTranslation();
  document.title = translation.document_title;
  
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
		<HeroHome />
	    <AboutUs />
        <FeaturesBlocks />
        <Prices />
        <Parteneri />
        <Carrier />
		<ContactUs />
      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;