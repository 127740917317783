import React from 'react';
import ImageItem from '../components/ImageItem';

function Galery() {
  return ( 
      <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
              <div className="flex flex-wrap -m-4">
                  <ImageItem url={require('../images/truck1.jpg').default} />
				  <ImageItem url={require('../images/truck6.jpg').default} />
				  <ImageItem url={require('../images/truck5.jpg').default} />
				  <ImageItem url={require('../images/truck13.jpg').default} />
				  <ImageItem url={require('../images/truck7.jpg').default} />
				  <ImageItem url={require('../images/truck12.jpg').default} />
				  <ImageItem url={require('../images/truck9.jpg').default} />
				  <ImageItem url={require('../images/truck11.jpg').default} />				  
				  <ImageItem url={require('../images/truck8.jpg').default} />
              </div>
          </div>
      </section>
   );
}

export default Galery;