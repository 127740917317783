import * as React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation
} from 'react-router-dom'

import useTranslation from "../components/customHooks/translations";

class CheckItem extends React.Component {
    render() {
        return (
            <input type="checkbox" class="form-checkbox mr-3 text-green-600" checked />
        );
    }
}

class UlRow extends React.Component {
    render() {
      return (
          <li className="mt-2 text-center bg-green-600 bg-opacity-100 rounded p-1 text-white shadow-lg">
              <CheckItem />
              {this.props.name}
          </li>
      );
    }
  }

function CareerItem(props) {
    var expectacionsRows=[];
    var whatOfferRows=[];
    if (props.expectacions != undefined) {
        props.expectacions.forEach(element => {
            expectacionsRows.push(<UlRow name={element} />);
        });
    }

    if (props.offers != undefined) {
        props.offers.forEach(element => {
            whatOfferRows.push(<UlRow name={element} />);
        });
    }
   const translation = useTranslation();
   
  return (
      <div className="relative flex flex-col items-center p-6 bg-white border border-gray-200 m-1 rounded shadow-lg">
          <h4 className="text-xl text-center font-bold leading-snug tracking-tight mb-1 border-b border-gray-200 p-2">{props.title}</h4>
          <p className="text-gray-600 text-center ">{props.content}</p>
          <p className="text-center m-1 border-b border-gray-200 font-bold">{translation.carrier_expectation}</p>
          <ul className="list-none ">{expectacionsRows}</ul>
          <p className="text-center mt-5 m-1  border-b border-gray-200 font-bold">{translation.carrier_offers}</p>
          <ul lassName="list-none ">{whatOfferRows}</ul>

          <a href="#contact" class="shadow-lg mt-10 bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
		  {translation.carrier_interest}
        </a>
      </div>
      
  );
}
export default CareerItem;