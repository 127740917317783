import React from "react";


function FeatureDetail(props) {
    return (
        <section className="text-gray-600 body-font overflow-hidden">
            <div className="container px-5 py-24 mx-auto">
                <div className="lg:w-4/5 mx-auto flex flex-wrap">
                    <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded shadow-lg" src={props.imgurl} />
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                        <h1 className="text-gray-900 text-center text-3xl title-font font-medium mb-10">{props.title}</h1>
                        <p className="leading-relaxed text-center">{props.detailtext}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default FeatureDetail;